import React from "react";
import { graphql } from "gatsby";
import Layout from "~/components/layout";
import { Trans } from "@lingui/macro";
import TutorCourses from "~/components/tutorCourses";
import SocialIcon from "~/components/socialIcon";
import SEO from "~/components/seo";
import Sanitize from "~/components/sanitize";
import PricesContext from "~/providers/pricesProvider";
import usePrices from "~/hooks/usePrices";

const ProfilePage = (props) => {
  const { data, pageContext } = props;
  const profile = data.vtutorStructuredProfilesContent;
  const courses = data.allVtutorStructuredContent.edges;

  // ACA es para los precios
  const ids = courses.map((course) => course.node.ID);
  const joinedIds = ids.join(",");
  // ------------------------
  const { data: prices } = usePrices(joinedIds);

  return (
    <Layout paths={pageContext.paths}>
      <SEO
        title="Home"
        lang={pageContext.locale}
        keywords={[`vtutor`, `cursos`, `courses`, `tutorials`]}
        location={pageContext.paths}
      />
      <div className="p-2 md:p-4 md:py-6">
        <div className="md:mr-auto md:ml-auto lg:max-w-6xl pl-2 pr-2 md:p-0">
          <div className="flex flex-col md:flex-row">
            <div
              className="rounded-brand bg-white shadow p-3 flex flex-col items-center justify-center"
              style={{ minHeight: 400, maxWidth: 350 }}
            >
              <img
                src={profile.avatar}
                style={{ height: 160, width: 160 }}
                className="rounded-full shadow mb-4"
                alt={profile.displayName}
              />
              <p className="font-medium text-xl mb-1">{profile.displayName}</p>
              <p className="mb-6 text-sm">{profile.headLine}</p>
              <div className="flex justify-between items-center leading-tight border-b border-gray-300 pb-3 mb-5">
                <div className="text-center mr-2">
                  <div className="font-medium text-xl">
                    {profile.courses.length}
                  </div>
                  <div className="text-sm text-gray-400">
                    <Trans>Courses created</Trans>
                  </div>
                </div>
                <div className="h-6 pt-3 pb-8 border-r border-gray-300 mx-4" />
                <div className="text-center ml-2">
                  <div className="font-medium text-xl">{profile.rating}</div>
                  <div className="text-sm text-gray-400">
                    <Trans>Stars Tutor Rating</Trans>
                  </div>
                </div>
              </div>
              <div className="text-center">
                {profile.social.facebook && (
                  <SocialIcon
                    social="facebook"
                    url={profile.social.facebook}
                    className="text-brand-link-gray text-3xl mr-4"
                  />
                )}
                {profile.social.twitter && (
                  <SocialIcon
                    social="twitter"
                    url={profile.social.twitter}
                    className="text-brand-link-gray text-3xl mr-4"
                  />
                )}
                {profile.social.instagram && (
                  <SocialIcon
                    social="instagram"
                    url={profile.social.instagram}
                    className="text-brand-link-gray text-3xl mr-4"
                  />
                )}
                {profile.social.linkedin && (
                  <SocialIcon
                    social="linkedin"
                    url={profile.social.linkedin}
                    className="text-brand-link-gray text-3xl mr-4"
                  />
                )}
                {profile.social.website && (
                  <SocialIcon
                    social="website"
                    url={profile.social.website}
                    rel="nofollow"
                    className="text-brand-link-gray text-3xl mr-4"
                  />
                )}
              </div>
            </div>
            <div className="flex-1 mt-6 md:mt-0 md:ml-12 flex items-center">
              <div className="course-description">
                <Sanitize html={profile.biography} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {courses.length > 0 && (
        <div
          style={{
            background:
              "linear-gradient(180deg, #F1F4F5 0%, rgba(241, 244, 245, 0) 100%)",
          }}
        >
          <div className="md:mr-auto md:ml-auto lg:max-w-6xl py-6 px-4 xl:px-1">
            <h3 className="mb-6">
              <Trans>Courses created</Trans>
            </h3>
            <div className="xl:px-6">
              <PricesContext.Provider value={prices}>
                <TutorCourses data={courses} loop={false} />
              </PricesContext.Provider>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export const query = graphql`
  query($ID: Int!, $courses: [Int]) {
    vtutorStructuredProfilesContent(ID: { eq: $ID }) {
      slug
      social {
        facebook
        instagram
        linkedin
        twitter
        website
      }
      lastName
      biography
      courses
      displayName
      firstName
      headLine
      avatar
      rating
    }
    allVtutorStructuredContent(
      sort: { fields: created_at, order: DESC }
      limit: 16
      filter: { ID: { in: $courses } }
    ) {
      edges {
        node {
          ID
          image
          tutor {
            name
            avatar
          }
          title
          students
          reviews_avg
          reviews {
            count
          }
          price_old
          price
          free
          permalink {
            category
            slug
          }
          language
          total_hours
          total_lectures
          duration
          discutions
          created_at
        }
      }
    }
  }
`;

export default ProfilePage;
