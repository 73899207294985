import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Carrousel from "~/components/Carrousel";
import CourseCardMobile from "~/components/courseCardMobile";

import * as actionCreators from "~/redux/actions/cart_actions";

const TutorCourses = (props) => {
  const courses = props.data;
  const { loop, enableButtons } = props;

  useEffect(() => {
    return () => {
      return false;
    };
  });

  const CarrouselData = {
    courses: courses,
    cart: props.cart,
    removeFromCartAction: props.removeFromCartAction,
    addToCartAction: props.addToCartAction,
  };

  return <Carrousel {...CarrouselData} {...{ loop, enableButtons }} />;
};

const mapStateToProps = ({ cart }) => {
  return {
    cart,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(actionCreators, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(TutorCourses);
